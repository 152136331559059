import React from 'react'
import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import MainNavbar from 'components/MainNavbar'
// import Sidebar from 'components/Sidebar'
import styles from './styles'

const PrivateRoute = ({
  component: Component, title, ...rest
}) => {
  const classes = styles()
  const groups = useSelector(state => state.auth.groups)

  return (
    <Route
      {...rest}
      render={props => (
        <div className={classes.container}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <MainNavbar groups={groups} />
            <div className={classes.header}>
              <h1 className={classes.title}>{title}</h1>
              <div className={classes.component}>
                <Component {...props} />
              </div>
            </div>
          </div>
        </div>
        )
      }
    />
  )
}

export default PrivateRoute
